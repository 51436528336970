<template>
  <div>
    <div class="header">
      <van-row gutter="10" @click.native="onClick">
        <van-col  span="24">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: .4rem;margin:.3rem 0 0 .2rem;"
          />
          <span style="font-size: .4rem; color: white;font-weight:bold;margin-left:.1rem">{{title}}</span>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style>
.header {
  height:1rem;
  line-height: 1rem;
  display: block;
}
</style>